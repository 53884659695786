export enum AccountType {
  ADMIN = 'ADMIN',
  TECHNICIAN = 'TECHNICIAN',
  CUSTOMER = 'CUSTOMER',
  MAIN = 'MAIN',
  VENDOR = 'VENDOR'
}

export enum CustomerRole {
  INDIVIDUAL = 'Individual',
  CORPORATE = 'Corporate'
}

export interface LoadingEmitData {
  display: boolean
  message: string
}

export interface UploadUrlDto {
  fileId: string
  preSignUrl: string
  loadUrl: string
  loadTempUrl?: string
}

export interface FilterQueryBase {
  offset?: number
  limit?: number
}

export interface FilterQuery {
  offset?: number
  limit?: number
  search?: string
  all?: boolean
  level?: any
  read?: boolean
  includeAll?: boolean
  serviceId?: string
  mainCategoryId?: string
}

export enum ResidentialType {
  HDB = 'HDB',
  Condo = 'Condo',
  Landed = 'Landed',
  Commercial = 'Commercial',
  Industrial = 'Industrial'
}

export enum EmployeeModule {
  Technician = 'technician',
  Vendor = 'vendor'
}
