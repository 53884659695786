import axios from 'axios'
import { RescheduleDto, TaskDto, UpdateTaskRequestBodyDto } from '../definition/booking.dto'
import { ENV } from '../initialization'

const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetTaskDetail = async (id: string) => {
  const url = `${DOMAIN}/api/booking/task/detail/${id}`
  const response = await axios.get<TaskDto>(url)
  return response.data
}

export const asyncSaveTask = async (data: UpdateTaskRequestBodyDto) => {
  const url = `${DOMAIN}/api/booking/task/update`
  const response = await axios.put<TaskDto>(url, data)
  return response.data
}

export const asyncRejectReschedule = async (id: string) => {
  const url = `${DOMAIN}/api/booking/task/reject-reschedule`
  const response = await axios.put<TaskDto>(url, { taskId: id })
  return response.data
}

export const asyncAcceptReschedule = async (data: RescheduleDto) => {
  const url = `${DOMAIN}/api/booking/task/accept-reschedule`
  const response = await axios.put<TaskDto>(url, data)
  return response.data
}
