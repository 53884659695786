import axios from 'axios'
import {
  AccountInfoDto,
  AddressType,
  ServiceReminderFrequencyDto,
  CustomerAddressDto,
  CustomerDataRetention,
  FilterQuery,
  ListAccountInfoResponseDto,
  ListAddressoResponseDto,
  ListExtensionRequestResponseDto,
  ListPointLogResponseDto,
  ListReferralResponseDto,
  NewCustomerRequestBodyDto,
  PointExtensionRequestFilterQuery,
  PointLogsFilterQuery,
  UpdateCustomerAddressDto,
  UpdateCustomerRequestBodyDto,
  UploadUrlDto
} from '../definition'
import { ENV } from '../initialization'
import { putToS3, removeExifDataImage } from '../utils'
import { toast } from 'react-toastify'

const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetListCustomers = async (data: FilterQuery): Promise<ListAccountInfoResponseDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/list`
  const response = await axios.get<ListAccountInfoResponseDto>(url, {
    params: data
  })
  return response.data
}

export const uploadAvatar = async (avatarFile: File, customerId?: string, avatarFileId?: string) => {
  const imageNotExif = await removeExifDataImage(avatarFile)
  const url = `${DOMAIN}/api/authentication/profile/get-upload-url`
  const getUrlResponse = await axios.post<UploadUrlDto>(url, {
    fileName: imageNotExif.name,
    fileId: avatarFileId,
    ownerId: customerId,
    dataType: 'avatar'
  })
  await putToS3(imageNotExif, getUrlResponse.data.preSignUrl)
  return getUrlResponse.data
}

export const asyncCreateCustomer = async (data: NewCustomerRequestBodyDto): Promise<AccountInfoDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/create`
  const rtData = await axios.post(url, data)
  return rtData.data
}

export const asyncGetCustomerDetail = async (id: string): Promise<AccountInfoDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/detail/${id}`
  const response = await axios.get(url)
  return response.data
}

export const asyncSaveCustomer = async (id: string, data: UpdateCustomerRequestBodyDto): Promise<void> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/save/${id}`
  await axios.post(url, data)
}

export const asyncDeleteCustomer = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/delete/${id}`
  await axios.delete(url)
}

export const asyncGetCustomerAddress = async (
  data: FilterQuery,
  addressType: AddressType,
  accountId: string
): Promise<ListAddressoResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/address`
  const response = await axios.get<ListAddressoResponseDto>(url, {
    params: { ...data, addressType: addressType, accountId }
  })
  return response.data
}

export const asyncCreateCustomerAddress = async (
  data: UpdateCustomerAddressDto
): Promise<CustomerAddressDto | undefined> => {
  const url = `${DOMAIN}/api/booking/address/admin`
  const rtData = await axios.post(url, data)
  return rtData.data
}

export const asyncUpdateCustomerAddress = async (
  data: UpdateCustomerAddressDto,
  addressId: string
): Promise<CustomerAddressDto | undefined> => {
  const url = `${DOMAIN}/api/booking/address/${addressId}`
  const rtData = await axios.put(url, data)
  return rtData.data
}

export const asyncDeleteCustomerAddress = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/booking/address/${id}`
  await axios.delete(url)
}

export const asyncGetDataRetention = async (): Promise<CustomerDataRetention> => {
  const url = `${DOMAIN}/api/authentication/get-retention-period`
  const response = await axios.get<CustomerDataRetention>(url)
  return response.data
}

export const asyncSaveDataRetention = async (data: CustomerDataRetention) => {
  const url = `${DOMAIN}/api/authentication/update-retention-period`
  const res = await axios.post<CustomerDataRetention>(url, data)
  return res.data
}

export const asyncGetCustomerReferrals = async (
  id: string,
  params: FilterQuery
): Promise<ListReferralResponseDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/referral/${id}/history`
  const response = await axios.get<ListReferralResponseDto>(url, {
    params
  })
  return response.data
}

export const asyncGetCustomerPointLogs = async (
  id: string,
  params: PointLogsFilterQuery
): Promise<ListPointLogResponseDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/point/${id}/history`
  const response = await axios.get<ListPointLogResponseDto>(url, {
    params
  })
  return response.data
}
export const asyncGetExtensionRequest = async (
  params: PointExtensionRequestFilterQuery
): Promise<ListExtensionRequestResponseDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/point/point-expiry-extension-request`
  const response = await axios.get<ListExtensionRequestResponseDto>(url, {
    params
  })
  return response.data
}
export const asyncAcceptExtensionRequest = async (
  id: string,
  newExpiryDate?: string
): Promise<{ success: boolean } | undefined> => {
  const url = `${DOMAIN}/api/authentication/point/approve-point-expiry-extension-request`
  try {
    await axios.put<ListExtensionRequestResponseDto>(url, {
      id,
      newExpiryDate
    })
    return { success: true }
  } catch (error: any) {
    const errMsg = error.response?.data?.error?.message || error.message
    toast.error(errMsg)
  }
}
export const asyncRejectExtensionRequest = async (
  id: string,
  reasonReject: string
): Promise<{ success: boolean } | undefined> => {
  const url = `${DOMAIN}/api/authentication/point/reject-point-expiry-extension-request`
  try {
    await axios.put<ListExtensionRequestResponseDto>(url, {
      id,
      reasonReject
    })
    return { success: true }
  } catch (error: any) {
    const errMsg = error.response?.data?.error?.message || error.message
    toast.error(errMsg)
  }
}

export const asyncGetConfigureBookingReminder = async (): Promise<ServiceReminderFrequencyDto | undefined> => {
  const url = `${DOMAIN}/api/booking/app-config/service-reminder-frequency`
  const rtData = await axios.get(url)
  return rtData.data
}

export const asyncConfigureBookingReminder = async (data: ServiceReminderFrequencyDto): Promise<any | undefined> => {
  const url = `${DOMAIN}/api/booking/app-config/service-reminder-frequency`
  const rtData = await axios.put(url, data)
  return rtData.data
}
