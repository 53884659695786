import { ProfileDto } from './account.dto'
import { ServiceCategoryDto } from './service.dto'

export interface VendorInfoDto {
  service: {
    name: string
    id: string
  }
  code: string
  contractPeriod: string
  contractPeriodFrom?: Date
  contractPeriodTo?: Date
  contractPeriodUrl: string
  email: string
  gradeTier: string
  id: string
  name: string
  proofOfLicense: string
  proofOfLicenseUrl: string
  serviceId: string
  status: VendorStatus
  uenNo: string
  avatarUrl?: string
  profile: ProfileDto
  mainCategoryId: string
  mainCategory: ServiceCategoryDto
}

export enum VendorStatus {
  Pending = 'pending',
  Active = 'active',
  Deactivated = 'deactivated'
}

export interface ListVendorInfoResponseDto {
  items: VendorInfoDto[]

  count: number
}

export interface NewVendorRequestBodyDto {
  name: string
  email?: string
  password: string
  contact: string
  mainCategoryId: string
  uenNo: string
  proofOfLicense: string
  proofIsTemporary?: boolean
  gradeTier: string
  contractPeriod: string
  contractPeriodFrom?: Date
  contractPeriodTo?: Date
  avatarId?: string
  avatarIsTemporary?: boolean
  countryCode: string
  code: string
}

export interface ImportExcelVendorResponseDto {
  added: number
  listSuccess: any[]
  listFail: any[]
}

export enum GradeTierOptions {
  A = 'Grade A',
  B = 'Grade B',
  C = 'Grade C',
  D = 'Grade D',
  E = 'Grade E'
}
