import axios from 'axios'
import {
  FilterQuery,
  ListPromoResponseDto,
  PromoCodeFilterQuery,
  PromoDto,
  PromoHistoryResponse,
  UpdatePromoBodyDto
} from '../definition'
import { ENV } from '../initialization'
const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetListPromoCodes = async (data: PromoCodeFilterQuery): Promise<ListPromoResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/promo-code`
  const response = await axios.get<ListPromoResponseDto>(url, {
    params: data
  })
  return response.data
}

export const asyncGetPromoCode = async (id: string): Promise<PromoDto> => {
  const response = await axios.get<PromoDto>(`${DOMAIN}/api/booking/promo-code/${id}`)
  return response.data
}

export const asyncSavePromoteCode = async (id: string, data: UpdatePromoBodyDto): Promise<void> => {
  const url = `${DOMAIN}/api/booking/promo-code/${id}`
  await axios.put(url, data)
}

export const asyncCreatePromoteCode = async (data: UpdatePromoBodyDto): Promise<void> => {
  const url = `${DOMAIN}/api/booking/promo-code`
  await axios.post(url, data)
}

export const asyncDeletePromoteCode = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/booking/promo-code/${id}`
  await axios.delete(url)
}

interface PromoteCodeHistoryFilter extends FilterQuery {
  fromDate?: Date
  toDate?: Date
}

export const asyncGetPromoteCodeHistory = async (
  code: string,
  filter?: PromoteCodeHistoryFilter
): Promise<PromoHistoryResponse> => {
  const url = `${DOMAIN}/api/booking/promo-code-used`
  const response = await axios.get(url, {
    params: {
      promoCode: code,
      ...filter
    }
  })

  return response.data
}
